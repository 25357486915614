<template>
  <div>
    <!-- <van-loading type="spinner" color="#1989fa" v-if="isShow1" /> -->
    <!-- <van-skeleton v-if="isShow1" :row="3" /> -->
    <!-- <van-pull-refresh v-model="loading" @refresh="onRefresh" v-else> -->
    <div>


      <!-- <div class="top">
        <div class="ab">年分值查询</div>
      </div> -->
      <div style="position: fixed; top: 0; z-index: 999">
        <div style="position: relative">
          <div>
            <img style="width: 100%" src="@/static/img_year_bg.png" alt="" />
          </div>
          <div class="mon">
            <van-cell @click="showPopup" :style="{ width: '100%', height: '50%' }"><span class="time"
                style="color: #295cc9">{{
              currentDate1 ? currentDate1 : "2023年"
            }}</span>
              <van-icon name="arrow-down" color="#295CC9" style="margin-left: 0.1rem" /></van-cell>
          </div>
          <van-button @click="getData" type="primary" style="position: absolute; right: 40px;bottom: 0.5rem;" plain
            hairline size="small">查询</van-button>
        </div>
      </div>

      <div class="box" v-if="data.length > 0">
        <div class="count" v-for="item in data" :key="item.id">
          <div class="wid_92">
            <div class="flx">
              <div class="time">{{ item.workmonth }}月</div>
              <div class="price">
                {{
              userInfo.scoring != 1
                ? item.realscore.toFixed(2)
                : item.realscore.toFixed(0)
            }}
              </div>
            </div>
            <div class="flx content">
              <div>
                <div class="flx6">
                  <div class="content_left">出工</div>
                  <div class="content_right">{{ item.workdays }}</div>
                </div>
                <div class="flx6">
                  <div class="content_left">基分</div>
                  <div class="content_right">
                    {{
              userInfo.scoring != 1
                ? item.score.toFixed(2)
                : item.score.toFixed(0)
            }}
                  </div>
                </div>
                <div class="flx6" v-if="item.isShow">
                  <div class="content_left">扣分</div>
                  <div class="content_right">
                    {{
              userInfo.scoring != 1
                ? item.deductscore.toFixed(2)
                : item.deductscore.toFixed(0)
            }}
                  </div>
                </div>
                <div class="flx6" v-if="item.isShow">
                  <div class="content_left">奖扣</div>
                  <div class="content_right">
                    {{
              userInfo.scoring != 1
                ? item.awardscore.toFixed(2)
                : item.awardscore.toFixed(0)
            }}
                  </div>
                </div>
                <div class="flx6" v-if="item.isShow && Option?.limit_patrol_mode?.status == 1">
                  <div class="content_left">日其他</div>
                  <div class="content_right">
                    {{
              userInfo.scoring != 1
                ? item.otheraward.toFixed(2)
                : item.otheraward.toFixed(0)
            }}
                  </div>
                </div>
                <div class="flx6" v-if="item.isShow">
                  <div class="content_left">月度奖扣</div>
                  <div class="content_right">
                    {{
              userInfo.scoring != 1
                ? item.monthDeduction.toFixed(2)
                : item.monthDeduction.toFixed(0)
            }}
                  </div>
                </div>
                <div class="flx6" v-if="item.isShow && Option?.limit_patrol_mode?.status == 1">
                  <div class="content_left">月其他</div>
                  <div class="content_right">
                    {{
              userInfo.scoring != 1
                ? item.monthOtherDeduction.toFixed(2)
                : item.monthOtherDeduction.toFixed(0)
            }}
                  </div>
                </div>
                <div v-if="Option?.limit_patrol_mode?.status == 1">
                  <div class="flx6" v-if="item.isShow">
                    <div class="content_left">精益日奖励</div>
                    <div class="content_right">
                      {{
              userInfo.scoring != 1
                ? item.jyscore.toFixed(2)
                : item.jyscore.toFixed(0)
            }}
                    </div>
                  </div>
                  <div class="flx6" v-if="item.isShow">
                    <div class="content_left">精益月细则</div>
                    <div class="content_right">
                      {{
              userInfo.scoring != 1
                ? item.jyscoreExam.toFixed(2)
                : item.jyscoreExam.toFixed(0)
            }}
                    </div>
                  </div>
                  <div class="flx6" v-if="item.isShow">
                    <div class="content_left">精益月其他</div>
                    <div class="content_right">
                      {{
              userInfo.scoring != 1
                ? item.monthOtherJyDeduction.toFixed(2)
                : item.monthOtherJyDeduction.toFixed(0)
            }}
                    </div>
                  </div>
                  <div class="flx6" v-if="item.isShow">
                    <div class="content_left">精益月奖扣</div>
                    <div class="content_right">
                      {{
              userInfo.scoring != 1
                ? item.monthJyDeduction.toFixed(2)
                : item.monthJyDeduction.toFixed(0)
            }}
                    </div>
                  </div>
                  <div class="flx6" v-if="item.isShow">
                    <div class="content_left">精益总额</div>
                    <div class="content_right">
                      {{
              userInfo.scoring != 1
                ? item.jyTotalScore.toFixed(2)
                : item.jyTotalScore.toFixed(0)
            }}
                    </div>
                  </div>
                </div>
                <div class="flx6" v-if="item.isShow">
                  <div class="content_left">三工</div>
                  <div class="content_right" v-if="item.evaluate == -1">
                    试用
                  </div>
                  <div class="content_right" v-if="item.evaluate == 0">
                    合格
                  </div>
                  <div class="content_right" v-if="item.evaluate == 1">
                    优秀
                  </div>
                </div>
                <div class="flx6" v-if="item.isShow">
                  <div class="content_left">对等</div>
                  <div class="content_right">
                    {{
              userInfo.scoring != 1
                ? item.swapaward.toFixed(2)
                : item.swapaward.toFixed(0)
            }}
                  </div>
                </div>
                <div class="flx6" v-if="item.isShow">
                  <div class="content_left">总分</div>
                  <div class="content_right">
                    {{
              userInfo.scoring != 1
                    ? item.realscore.toFixed(2)
                    : item.realscore.toFixed(0)
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div class="bor"></div>
            <div style="text-align: center" @click="getIshow(item)">
              <van-icon v-if="item.isShow" name="arrow-up" color="#D9D9D9" style="margin: 0.26rem auto" />
              <van-icon v-else name="arrow-down" color="#D9D9D9" style="margin: 0.26rem auto" />
            </div>
          </div>
        </div>
      </div>

      <van-empty class="box" v-else description="暂无数据" />
      <!-- </van-pull-refresh> -->
    </div>

    <van-popup v-model:show="show" position="bottom">
      <van-picker v-model="currentDate" :columns="columns" :default-index="select" title="选择年" @confirm="confirm"
        @cancel="cancel" />
    </van-popup>
  </div>
</template>
<script>
import {
  getCurrentInstance,
  onMounted,
  reactive,
  nextTick,
  toRefs,
} from "vue";
// 全页面背景色配置
import { useRoute, useRouter } from "vue-router";
import { format } from "@/utils/index.js";
export default {
  setup() {
    const router = useRouter();
    const useRouteone = useRoute();
    const { proxy } = getCurrentInstance();
    const reactiveParams = reactive({
      currentDate: format(new Date(), "yyyy"),
      currentDate1: format(new Date(), "yyyy年"),
      isShow: false,
      columns: [],
      select: null,
      data: [],
      show: false,
      loading: false,
      isShow1: true,
      userInfo: {},
      Option: JSON.parse(localStorage.getItem("Option"))
        ? JSON.parse(localStorage.getItem("Option"))
        : JSON.parse(sessionStorage.getItem("Option")),
    });
    const getYear = () => {
      let year = JSON.parse(format(new Date(), "yyyy"));
      for (var i = year - 5; i < year + 5; i++) {
        reactiveParams.columns.push(i);
      }
      console.log(reactiveParams.columns);
      reactiveParams.select = reactiveParams.columns.indexOf(year);
    };
    const onRefresh = () => {
      reactiveParams.loading = true;
      getData();
    };
    const getData = async () => {
      let userInfo = useRouteone.query.userInfo
        ? JSON.parse(useRouteone.query.userInfo)
        : sessionStorage.getItem("userInfo")
          ? JSON.parse(sessionStorage.getItem("userInfo"))
          : JSON.parse(localStorage.getItem("userInfo"));
      reactiveParams.userInfo = userInfo;
      console.log(userInfo, "shuj ");
      let pamas = {
        // companyId: userInfo.companyId,
        // groupId: userInfo.groupId,
        workyear: format(reactiveParams.currentDate, "yyyy"),
        customerId: userInfo.id,
      };
      reactiveParams.data = [];
      await proxy.$Api.get(proxy.$scor.newgetAllByYear, pamas).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          nextTick(() => {
            reactiveParams.isShow1 = false;

            reactiveParams.loading = false;
            let data = res.data.data;
            for (let i = 0; i < data.length; i++) {
              data[i].isShow = false;
              reactiveParams.data.push(data[i]);
            }
            console.log(reactiveParams.data);
          });
        } else {
          reactiveParams.isShow1 = false;
        }
      });
    };
    const showPopup = () => {
      reactiveParams.show = true;
      console.log(reactiveParams.show);
    };

    const confirm = async (e) => {
      reactiveParams.currentDate1 = e + "年";
      reactiveParams.currentDate = e;
      console.log(reactiveParams.currentDate);
      await cancel();
      await getData();
    };
    const cancel = () => {
      reactiveParams.show = false;
    };
    const getIshow = (e) => {
      console.log(e);
      e.isShow = e.isShow ? false : true;
    };
    onMounted(() => {
      getData();
      getYear();
    });
    // 开始触发事件
    return {
      ...toRefs(reactiveParams),
      router,
      useRouteone,

      showPopup,
      confirm,
      cancel,
      getIshow,
      getYear,
      getData,
      onRefresh,
    };
  },
};
</script>
<style lang="scss" scoped>
.top {
  // height: 3.4rem;
  background: #295cc9;
  position: relative;
  // display: flex;
  // align-items: end;
  // vertical-align: bottom;
}

.ab {
  padding-left: 0.4rem;
  font-size: 0.32rem;
  font-family: Noto Sans S Chinese;
  font-weight: bold;
  color: #ffffff;
  position: absolute;
  bottom: 0;
}

.img {
  width: 0.98rem;
  height: 0.9rem;
}

.img img {
  width: 100%;
  height: 100%;
}

.menu {
  width: 5.4rem;
  // margin: -70px auto;
  position: absolute;
  top: 0.75rem;
  left: 1rem;
}

.menu div div:last-child {
  font-size: 0.27rem;
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  color: #000000;
}

.time {
  font-size: 0.3rem;
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  color: #1a1a1a;
}

.price {
  font-size: 0.3rem;
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  color: #cc0000;
}

.content {
  margin-top: 0.14rem;
}

.content div .flx6 {
  margin-top: 0.2rem;
}

.content_left {
  width: 1.4rem !important;
  font-size: 0.28rem;
  font-weight: 400;
  width: 1.2rem;
  color: #8c8c8c;
}

.content_right {
  font-size: 0.28rem;
  font-weight: 400;
  color: #1a1a1a;
  margin-left: 0.94rem;
}

.van-cell {
  background: transparent !important;
}

.mon {
  position: absolute;
  bottom: 0.32rem;
  left: 0.64rem;
}

.count {
  width: 6.8rem;
  min-height: 3rem;
  margin: 0.2rem auto;
  background: white;
  border-radius: 0.1rem;
  font-size: 0.28rem;
}

.wid_92 {
  width: 92%;
  margin: 0 auto;
  padding-top: 0.28rem;
}

.bor {
  margin-top: 0.26rem;
  width: 100%;
  border-bottom: 0.02rem solid #f2f2f2;
}
</style>
